import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/login.vue';
import playMusicInfo from '../views/playMusicInfo.vue';
import RegisterView from '../views/register.vue';
import ShareView from '../views/share.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/musicinfo',
    name: 'musicinfo',
    component: playMusicInfo
  },
  {
    path: '/share',
    name: 'share',
    component: ShareView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
