<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style>
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
</style>
